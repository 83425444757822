import { Table as AntTable } from "antd";
import React from "react";
import { useParams } from "react-router-dom";

import { getFinanceTableColumnTitle } from "pages/Finance/Summary/ui/TableSide/Table/utils";
//TODO_V2_FINANCE
import { isQuartalColumns } from "pages/Finance/_TODO/isFinanceFooter";
//TODO_V2_FINANCE
import { useSummaryScroll } from "pages/Finance/_TODO/scroll/useSummaryScroll";

import { useFinanceFiltersContext } from "../../contexts/FinanceFiltersContext/FinanceFiltersContext";
import { useFinanceMeasure } from "features/financeMeasure";

import { MonthAndQuarterArray, MonthArray } from "constants/constant";

import styles from "./FinanceTableHead.module.scss";

const { Column, ColumnGroup } = AntTable;

//TODO_V2_FINANCE
const FinanceTableHead = () => {
  const { objectId, projectId } = useParams<Record<"objectId" | "projectId", string>>();
  const { measureInTitle } = useFinanceMeasure();
  const { tableRef, year } = useSummaryScroll();

  const { financeView } = useFinanceFiltersContext();
  const getTitle = getFinanceTableColumnTitle(financeView, measureInTitle);

  return (
    <AntTable dataSource={[]} size="small" scroll={{ x: 1300 }} pagination={false}>
      {isQuartalColumns(objectId, projectId)
        ? MonthAndQuarterArray.map((month, idx) => (
            <ColumnGroup title={`${month.label} ${year}`} key={month.name}>
              <Column
                title={getTitle("План")}
                dataIndex={`plan_${month.id}`}
                key={`plan_${month.id}`}
                width={116}
                ellipsis
              />
              <Column
                title={getTitle("Факт")}
                dataIndex={`fact_${month.id}`}
                key={`fact_${month.id}`}
                width={116}
                ellipsis
              />
              <Column
                title={getTitle("Итого", true)}
                dataIndex={`diff_${month.id}`}
                key={`diff_${month.id}`}
                width={116}
                ellipsis
              />
            </ColumnGroup>
          ))
        : MonthArray.map((month, idx) => (
            <ColumnGroup title={`${month.label} ${year}`} key={month.name}>
              <Column
                title={getTitle("План")}
                dataIndex={`plan_${month.id}`}
                key={`plan_${month.id}`}
                width={116}
                ellipsis
              />
              <Column
                title={getTitle("Факт")}
                dataIndex={`fact_${month.id}`}
                key={`fact_${month.id + 1}`}
                width={116}
                ellipsis
              />
              <Column
                title={getTitle("Итого", true)}
                dataIndex={`diff_${month.id}`}
                key={`diff_${month.id}`}
                width={116}
                ellipsis
              />
            </ColumnGroup>
          ))}
    </AntTable>
  );
};

export default FinanceTableHead;
