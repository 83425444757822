import { Table as AntTable } from "antd";
import React from "react";
import { useParams } from "react-router-dom";

import { isQuartalColumns } from "pages/Finance/_TODO/isFinanceFooter";
import { useSummaryScroll } from "pages/Finance/_TODO/scroll/useSummaryScroll";

import Column from "antd/es/table/Column";
import ColumnGroup from "antd/es/table/ColumnGroup";
import { useFinanceMeasure } from "features/financeMeasure";
import { MonthArray } from "shared/const/dates";

import { MonthAndQuarterArray } from "constants/constant";
import { LOCALIZATION_CONFIG } from "constants/localization";

const ForecastTableHead = () => {
  const { projectId, objectId } = useParams<Record<string, string>>();
  const { measureInTitle } = useFinanceMeasure();
  const { year } = useSummaryScroll();

  return (
    <AntTable dataSource={[]} size="small" scroll={{ x: 1300 }} pagination={false}>
      {isQuartalColumns(objectId, projectId)
        ? MonthAndQuarterArray.map((month) => (
            <ColumnGroup title={`${month.label} ${year}`} key={month.name}>
              <Column
                title={`План ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
                dataIndex={`plan_${month.id}`}
                key={`plan_${month.id}`}
                width={116}
                ellipsis
              />
              <Column
                title={`Факт ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
                dataIndex={`fact_${month.id}`}
                key={`fact_${month.id}`}
                width={116}
                ellipsis
              />
              <Column
                title={`Итого ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
                dataIndex={`diff_${month.id}`}
                key={`diff_${month.id}`}
                width={116}
                ellipsis
              />
            </ColumnGroup>
          ))
        : MonthArray.map((month, idx) => (
            <ColumnGroup title={`${month.label} ${year}`} key={month.name}>
              <Column
                title={`План ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
                dataIndex={`plan_${idx}`}
                key={`plan_${idx}`}
                width={116}
                ellipsis
              />
              <Column
                title={`Факт ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
                dataIndex={`fact_${idx}`}
                key={`fact_${idx}`}
                width={116}
                ellipsis
              />
              <Column
                title={`Итого ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
                dataIndex={`diff_${idx}`}
                key={`diff_${idx}`}
                width={116}
                ellipsis
              />
            </ColumnGroup>
          ))}
    </AntTable>
  );
};

export default ForecastTableHead;
