import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  summaryYearSelector,
  tableScrollPositionSelector,
  tableViewWidthPercentSelector,
} from "pages/Finance/_TODO/scroll/summaryScrollReducer/selectors";

import { summaryActions } from "./scroll/summaryScrollReducer/actions";

import { SCROLL_EVENT_TARGETS } from "./scroll/summaryScrollReducer/constants";

export const useFinanceSummaryMonthScrollBar = () => {
  const dispatch = useDispatch();
  const year = useSelector(summaryYearSelector);
  const tableScrollPosition = useSelector(tableScrollPositionSelector);
  const tableViewWidthPercent = useSelector(tableViewWidthPercentSelector);

  const handleYearChange = (year: number) => {
    dispatch(summaryActions.setYear(year));
  };

  const setTableScrollPosition = useCallback((x: number, sliderElement: HTMLElement) => {
    dispatch(
      summaryActions.setTableScrollPosition({
        scrollPosition: x,
        containerWidth: sliderElement.offsetWidth,
        eventFrom: SCROLL_EVENT_TARGETS.SLIDER,
      })
    );
  }, []);

  return {
    year,
    handleYearChange,
    setTableScrollPosition,
    tableScrollPosition,
    tableViewWidthPercent,
  };
};
