import { useEffect, useRef } from "react";

export interface IUseChartDragProps {
  targetRef: React.RefObject<HTMLElement>;
}

export const useFinanceDrag = ({ targetRef }: IUseChartDragProps) => {
  const isDraggingActive = useRef(false);
  const rafId = useRef<number | null>(null);
  const delta = useRef({ x: 0, y: 0 });

  useEffect(() => {
    const target = targetRef.current?.parentElement;
    console.log(123, targetRef.current, targetRef.current?.parentElement);
    if (!target) return;

    const enableDragging = (e: PointerEvent) => {
      //target.style.scrollBehavior = "auto";
      isDraggingActive.current = true;
      document.body.style.userSelect = "none";
      window.addEventListener("pointermove", watchMove, { passive: true });
    };

    const watchMove = (e: PointerEvent) => {
      console.log(e);
      if (!isDraggingActive.current) return;
      delta.current = { x: delta.current.x - e.movementX, y: delta.current.y - e.movementY };
      if (rafId.current) return;
      //TODO сделать нормально со всеми скроллами в финансах
      const header = document.querySelector<HTMLDivElement>(".ant-table-content");
      const tableScrollableElement = targetRef.current?.querySelectorAll<HTMLDivElement>(".ant-table-content")[0];
      rafId.current = requestAnimationFrame(() => {
        target.scrollLeft += delta.current.x;
        target.scrollTop += delta.current.y;
        !!header && (header.scrollLeft += delta.current.x);
        !!tableScrollableElement && (tableScrollableElement.scrollLeft += delta.current.x);
        !!tableScrollableElement && (tableScrollableElement.scrollTop += delta.current.y);
        rafId.current = null;
        delta.current = { x: 0, y: 0 };
      });
    };

    const disableDragging = () => {
      isDraggingActive.current = false;
      window.removeEventListener("pointermove", watchMove);
      document.body.style.userSelect = "";
      target.style.scrollBehavior = "";
    };

    target.addEventListener("pointerdown", enableDragging);
    window.addEventListener("pointerup", disableDragging);

    return () => {
      target.removeEventListener("pointerdown", enableDragging);
      window.removeEventListener("pointerup", disableDragging);
    };
  }, [targetRef.current]);
};
