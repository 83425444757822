//@ts-nocheck
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, RouteComponentProps, Switch, useParams } from "react-router-dom";

import { addExpenditure } from "../../../redux/modules/common/building/materials/specification";

import ExpenditureForm from "../../UI/_TODO/ExpenditureForm/ExpenditureForm";
import ForbiddenPage from "app/routes/components/ForbiddenPage/ForbiddenPage";
import LabeledSwitch from "components/UI/atoms/_TODO/LabeledSwitch";

import TabBarNotLinks from "../../../shared/ui/controls/TabBar/TabBarNotLinks";
import { MaterialsModalsContext } from "./common/MaterialsModalsContext/MaterialsModalsContext";
import { MaterialsInvalidationKeyContextProvider } from "./common/_MaterialsInvalidationKeyContext/_MaterialsInvalidationKeyContext";
import Estimate from "./tabs/Estimate/Estimate";
import Mims from "./tabs/Mims/MimsPage";
import Specification from "./tabs/Specification/Specification";
import BottomSliderMaterials from "./ui/BottomSliderMaterials";
import Chapter from "./ui/Chapter";
import ImportModal from "./ui/ImportModal";
import TemplateBase from "features/templates/TemplateBase/TemplateBase";
import ButtonBase from "shared/ui/controls/ButtonBase";
import ListPageHeaderTemplate from "shared/ui/layout/ListPageHeaderTemplate/ListPageHeaderTemplate";

import { ADDITIONAL, ESTIMATE, MIMS, SPECIFICATION, TABS_IDS } from "./constants";

import { useTypedParams } from "utils/hooks/useTypedParams";
import { useUrlModuleWithObjectId } from "utils/hooks/useUrlModuleWithObjectId";

import { materialsUtils } from "./utils";

import CrossRoundedIcon from "../../../images/icons/CrossRoundedIcon";
import TwoHorizontalSmallLinesIcon from "images/icons/TwoHorizontalSmallLinesIcon";

import styles from "./Materials.module.scss";

const Materials = ({ history }: RouteComponentProps) => {
  const rootPath = useUrlModuleWithObjectId();
  const { objectId, tab: activeTabId } = useTypedParams();
  const dispatch = useDispatch();

  const [isOpenModalAddSection, setIsOpenModalAddSection] = useState(false);
  const [isOpenModalAddExpenditures, setIsOpenModalAddExpenditures] = useState(false);
  const [isOpenModalImport, setIsOpenModalImport] = useState(false);
  const [isSwitch, setIsSwitch] = useState(false);
  const [titleBtn, setTitleBtn] = useState("Добавить раздел");
  const [idSubsection, setIdSubsection] = useState(null);
  const [activeSectionId, setActiveSectionId] = useState(null);
  const [addPosition, setAddPosition] = useState(false);

  const handleChangeActiveTab = useCallback(
    (tabId: string) => {
      history.push(`/${rootPath}/materials/${tabId}`);
    },
    [history, rootPath]
  );

  useEffect(() => {
    if (!activeTabId) handleChangeActiveTab(ESTIMATE);
  }, []);

  const closeModalAddSection = () => {
    setIsOpenModalAddSection(false);
  };

  const closeModalAddExpenditures = () => {
    setIsOpenModalAddExpenditures(false);
  };

  const closeModalImport = () => {
    setIsOpenModalImport(false);
  };

  useEffect(() => {
    if ([SPECIFICATION].includes(activeTabId)) {
      setAddPosition(false);
    }
  }, [activeTabId]);

  const submitAddPositionForm = useCallback(
    (values: any) => {
      closeModalAddExpenditures();
      dispatch(
        addExpenditure(
          objectId,
          values.created_from_estimate ? ADDITIONAL : activeTabId,
          {
            ...values,
            section_id: activeSectionId,
            ret_own_mat: false,
          },
          undefined
        )
      );
    },
    [objectId, activeTabId, closeModalAddExpenditures, activeSectionId]
  );

  const {
    haveAdditionalPermission,
    haveEditAdditionalPermission,
    haveEditEstimatePermission,
    haveEditSpecificationPermission,
    haveEstimatePermission,
    haveSpecificationPermission,
  } = materialsUtils.useMaterialsPermissions();

  useEffect(() => {
    if (!haveEstimatePermission && haveSpecificationPermission) {
      handleChangeActiveTab(SPECIFICATION);
    }
  }, [haveEstimatePermission, haveSpecificationPermission]);

  if (!haveEstimatePermission && !haveSpecificationPermission && !haveAdditionalPermission) {
    return (
      <TemplateBase>
        <ForbiddenPage />
      </TemplateBase>
    );
  }

  if (activeTabId === SPECIFICATION) {
    return (
      <MaterialsInvalidationKeyContextProvider>
        <MaterialsModalsContext
          isOpenModalImport={isOpenModalImport}
          setIsOpenModalImport={setIsOpenModalImport}
          isOpenModalAddExpenditures={isOpenModalAddExpenditures}
          setIsOpenModalAddExpenditures={setIsOpenModalAddExpenditures}
          isOpenModalAddSection={isOpenModalAddSection}
          setIsOpenModalAddSection={setIsOpenModalAddSection}
        >
          <Specification objectId={objectId} canEdit={haveEditSpecificationPermission}>
            <TabBarNotLinks
              tabs={[
                {
                  text: "По смете",
                  id: ESTIMATE,
                  isHidden: !haveEstimatePermission,
                },
                {
                  text: "Спецификация",
                  id: SPECIFICATION,
                  isHidden: !haveSpecificationPermission,
                },
                {
                  text: "МиМ",
                  id: MIMS,
                },
              ]}
              activeId={activeTabId}
              onClick={handleChangeActiveTab}
              className={styles.tabs}
            />
          </Specification>
          <ImportModal objId={objectId} close={closeModalImport} isOpen={isOpenModalImport} />
        </MaterialsModalsContext>
      </MaterialsInvalidationKeyContextProvider>
    );
  }

  return (
    <>
      <MaterialsInvalidationKeyContextProvider>
        <BottomSliderMaterials isOpen={isOpenModalAddSection} closeFunc={closeModalAddSection}>
          <Chapter
            titleBtn={titleBtn}
            id={idSubsection}
            label={idSubsection ? "подраздел" : "раздел"}
            name={idSubsection ? "Наименование подраздела" : "Наименование раздела"}
            placeholder={idSubsection ? "Введите наименование подраздела" : "Введите наименование раздела"}
            objId={objectId}
            typeUrl={activeTabId}
            close={closeModalAddSection}
          />
        </BottomSliderMaterials>
        <BottomSliderMaterials isOpen={isOpenModalAddExpenditures} closeFunc={closeModalAddExpenditures}>
          <div className={styles.headerContainer}>
            <div className={styles.block1Title}>
              <span className={styles.title}>Добавить позицию</span>
              <div className={styles.closeIcon} onClick={closeModalAddExpenditures}>
                <CrossRoundedIcon />
              </div>
            </div>
          </div>
          <ExpenditureForm submit={submitAddPositionForm} isServices={activeTabId === MIMS} />
        </BottomSliderMaterials>
        <ImportModal objId={objectId} close={closeModalImport} isOpen={isOpenModalImport} />
        <TemplateBase>
          <ListPageHeaderTemplate className={styles.header}>
            <TabBarNotLinks
              tabs={[
                {
                  text: "По смете",
                  id: ESTIMATE,
                  isHidden: !haveEstimatePermission,
                },
                {
                  text: "Спецификация",
                  id: SPECIFICATION,
                  isHidden: !haveSpecificationPermission,
                },
                {
                  text: "МиМ",
                  id: MIMS,
                },
              ]}
              activeId={activeTabId}
              onClick={handleChangeActiveTab}
              className={styles.tabs}
            />
            {haveEditEstimatePermission && activeTabId === ESTIMATE && (
              <div className={styles.boxEstimate}>
                <div className={styles.switchBox}>
                  <LabeledSwitch
                    label={"Просмотр"}
                    labelRight={"Редактирование"}
                    onChange={() => setIsSwitch(!isSwitch)}
                    value={isSwitch}
                  />
                </div>
              </div>
            )}
            {(activeTabId === SPECIFICATION || activeTabId === MIMS) && haveEditSpecificationPermission && (
              <div className={styles.actionBlock}>
                {titleBtn !== "Добавить позицию" && (
                  <ButtonBase buttonPrimaryEmpty onClick={() => setIsOpenModalAddSection(true)}>
                    {titleBtn}
                  </ButtonBase>
                )}
                {addPosition && activeTabId !== MIMS && (
                  <ButtonBase buttonPrimaryEmpty onClick={() => setIsOpenModalAddExpenditures(true)}>
                    Добавить позицию
                    <span className={styles.plusIcon}>
                      <TwoHorizontalSmallLinesIcon />
                    </span>
                  </ButtonBase>
                )}
                <ButtonBase
                  className={styles.importButton}
                  onClick={() => setIsOpenModalImport(true)}
                  medium
                  buttonPrimaryEmpty
                >
                  Импорт
                </ButtonBase>
              </div>
            )}
          </ListPageHeaderTemplate>

          <Switch>
            <Route
              path={`/objects/:objectId/materials/${ESTIMATE}`}
              render={() => <Estimate objectId={objectId} isSwitch={isSwitch} />}
            />
            <Route
              path={`/objects/:objectId/materials/${SPECIFICATION}`}
              render={() => (
                <Specification
                  titleBtn={titleBtn}
                  setIdSubsection={setIdSubsection}
                  setActiveSectionId={setActiveSectionId}
                  setTitleBtn={setTitleBtn}
                  objectId={objectId}
                  setAddPosition={setAddPosition}
                  canEdit={haveEditSpecificationPermission}
                  idSubsection={idSubsection}
                />
              )}
            />
            <Route
              path={`/objects/:objectId/materials/${MIMS}`}
              render={() => (
                <Mims
                  setIdSubsection={setIdSubsection}
                  setTitleBtn={setTitleBtn}
                  objectId={objectId}
                  typeUrl={activeTabId}
                  setAddPosition={setAddPosition}
                  canEdit={haveEditAdditionalPermission}
                />
              )}
            />
            <Redirect to={`/objects/${objectId}/materials/${ESTIMATE}`} />
          </Switch>
        </TemplateBase>
      </MaterialsInvalidationKeyContextProvider>
    </>
  );
};

export default Materials;
