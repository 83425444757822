import { message } from "antd";
import cn from "classnames";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import { approvedIntervalsSelector } from "../../../../../redux/modules/common/building/process";
import {
  deleteExpendituresGroups,
  updateExpendituresGroup
} from "../../../../../redux/modules/common/building/shedules";

import { VARIANTS } from "components/UI/_TODO/Expenditure/constants";
import EditExpendituresInHandler from "pages/Handler/ui/ProHandler/components/AddExpenditure/EditExpendituresInHandler";
import Checkbox from "../../../../../_LEGACY/UI/__TODO/Checkbox/Checkbox";
import AddRelationsModal from "../../../../UI/_TODO/Expenditure/components/AddRelationsModal/AddRelationsModal";
import DefaultColumns from "../../../../UI/_TODO/Expenditure/components/DefaultColumns/DefaultColumns";
import Info from "../../../../UI/_TODO/Expenditure/components/Info/Info";
import ExpenditureGroupModal from "../../../../UI/_TODO/ExpenditureGroupModal/ui/ExpenditureGroupModal";

import BuildingSectionProvidersPopup from "../BuildingSection/BuildingSectionProvidersPopup/BuildingSectionProvidersPopup";
import ChartPopup from "../ChartPopup/ChartPopup";
import ExpenditureAllPlansPopup from "../ExpenditureAllPlansPopup/ExpenditureAllPlansPopup";
import BuildingMatchingModal from "../MatchingModal/BuildingMatchingModal";

import { PLAN } from "../../constants";
import { TabsEnum } from "../../enums";
import { BuildingIntervalsByCategory } from "../../types";

import { useUrlModule } from "../../../../../utils/hooks/useUrlModule";
import { useObjectId } from "../../../Documents/hooks/useObjectId";
import { buildingExpHooks } from "./hooks";

import { dropNonSignificantZeros } from "../../../../../utils/formatters/dropNonSignificantZeros";
import { makeDatesLimitFilter } from "../../utils";

import CheckDouble from "../../../../../images/icons/CheckDouble";

import styles from "./BuildingExpenditure.module.scss";

export interface IBuildingExpenditureProps {
  className?: string;
  buildingId: number;
  expenditureOrGroup: any;
  intervals: BuildingIntervalsByCategory;
  loadProduct: () => void;
  tab: TabsEnum;
  isShared: boolean;
  sectionName?: string;
  isChecked: boolean;
  onCheck: (e: ChangeEvent) => void;
  canCheck: boolean;
  actions: any;
  loadSectionExpenditures: () => void;
  loadSectionGroups: () => void;
  permissions?: Record<string, boolean>;
  parentId: number;
  isKSHidden: boolean;
  lsrPlanData: any;
  canViewFiles?: boolean;
  isExpendituresGroup?: boolean;
  activeTab: string;
  factIntervals?: any[];
  planIntervals?: any[];
  onOpenApprovedPlanModal?: any;
  dateStart: string;
  dateEnd: string;
  sectionId: number;
  sectionParentId: number;
  isEditing: boolean;
  setIsEditing: (id: number) => void;
  onCancelEdit: (id: number) => void;
  directlyAction?: React.ReactNode;
  loadSubSection?: any;
  constructionDateStart: string;
  constructionDateEnd: string;
}

const BuildingExpenditure: React.FC<IBuildingExpenditureProps> = ({
  className,
  buildingId,
  expenditureOrGroup,
  intervals,
  loadProduct,
  tab,
  isShared,
  sectionName,
  isChecked,
  onCheck,
  canCheck,
  actions,
  loadSectionExpenditures,
  loadSectionGroups,
  permissions = {},
  parentId,
  isKSHidden,
  lsrPlanData,
  canViewFiles = true,
  isExpendituresGroup = false,
  activeTab,
  factIntervals,
  planIntervals,
  onOpenApprovedPlanModal,
  dateStart,
  dateEnd,
  sectionId,
  sectionParentId,
  isEditing,
  setIsEditing,
  onCancelEdit,
  directlyAction,
  loadSubSection,
  constructionDateStart,
  constructionDateEnd,
}) => {
  const dispatch = useDispatch();
  const objectId = useObjectId(2);
  const moduleRoute = useUrlModule();
  const datesLimitFilter = useMemo(() => makeDatesLimitFilter({ dateStart, dateEnd }), [dateStart, dateEnd]);

  const approvedIntervals = useSelector(approvedIntervalsSelector)[parentId];

  const [isShowGroupModal, setIsShowGroupModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [relationOriginInfo, setRelationOriginInfo] = useState(null);

  const isProgressVariant = tab === VARIANTS.PROGRESS;

  const expenditureDisplayedCount = dropNonSignificantZeros(expenditureOrGroup.count);
  const intervalsInDatesRange = useMemo(() => {
    return Object.fromEntries(
      Object.entries(intervals).map(([category, intervalsByCategory]) => [
        category,
        intervalsByCategory?.filter(datesLimitFilter),
      ])
    );
  }, [intervals, datesLimitFilter]);
  const approvedIntervalsInDatesRange = useMemo(
    () => approvedIntervals?.filter(datesLimitFilter),
    [approvedIntervals, datesLimitFilter]
  );
  const planIntervalsInDatesRange = useMemo(
    () => planIntervals?.filter(datesLimitFilter),
    [planIntervals, datesLimitFilter]
  );
  const factIntervalsInDatesRange = useMemo(
    () => factIntervals?.filter(datesLimitFilter),
    [factIntervals, datesLimitFilter]
  );

  const {
    isInProductionIntervalCompleted,
    isPlanIntervalsCompleted,
    currentInProductionIntervals,
    currentPlanIntervals,
    groupedFactGroupIntervals,
    groupedPlanGroupIntervals,
    currentFactIntervals,
  } = buildingExpHooks.useIntervals({
    intervals: intervalsInDatesRange,
    approvedIntervals: approvedIntervalsInDatesRange,
    isExpanded,
    isProgressVariant,
    planIntervals: planIntervalsInDatesRange,
    factIntervals: factIntervalsInDatesRange,
    tab,
  });

  const { handleSetOverviewState, overviewState } = buildingExpHooks.useOverViewState({
    expenditureOrGroup,
    isExpendituresGroup,
  });
  //@ts-expect-error
  const handleCheck = useCallback(() => onCheck(!isChecked), [onCheck, isChecked]);

  const isHideCheckbox = activeTab === TabsEnum.plan && expenditureOrGroup.expenditure_type !== "work";

  const { canAddIntervals } = buildingExpHooks.useAddIntervals({ tab, expenditureOrGroup, permissions });

  const onExpand = useCallback(() => setIsExpanded((prevState) => !prevState), []);

  useEffect(() => {
    setIsExpanded(false);
  }, [tab]);

  const handleShowGroupModal = useCallback(() => {
    setIsShowGroupModal(true);
  }, []);

  const handleCloseGroupModal = useCallback(() => {
    setIsShowGroupModal(false);
  }, []);

  const handleUngroupExpenditure = useCallback(() => {
    dispatch(
      deleteExpendituresGroups(
        objectId,
        expenditureOrGroup?.id,
        compose(loadSectionExpenditures, loadSectionGroups, handleCloseGroupModal)
      )
    );
  }, [objectId, expenditureOrGroup, loadSectionExpenditures, loadSectionGroups, handleCloseGroupModal]);

  const handleEditExpenditure = useCallback(
    /* @ts-ignore */
    (data, errorCallback) => {
      dispatch(
        updateExpendituresGroup({
          buildingId: objectId,
          groupId: expenditureOrGroup.id,
          data,
          callback: compose(loadSectionExpenditures, loadSectionGroups, () => message.success("Успешно обновлено")),
          errorCallback,
        })
      );
    },
    [objectId, expenditureOrGroup, loadSectionExpenditures, loadSectionGroups]
  );

  const { isIndicatorDisplayed } = buildingExpHooks.useBuilidngExpIndicators({
    sectionId,
    sectionParentId,
    dateEnd,
    dateStart,
    expenditureOrGroup,
  });

  const showMatching = activeTab === PLAN && expenditureOrGroup.is_default;

  const showDoubleCHeck = !canCheck && expenditureOrGroup.providers?.length > 0;

  return (
    <>
      {!isEditing && (
        <div
          className={cn(
            styles.container,
            styles[tab],
            { [styles.expanded]: isExpanded, [styles.withIndicator]: isIndicatorDisplayed },
            className
          )}
        >
          <div className={cn({ [styles.content]: true, [styles.contentWithActions]: !!directlyAction })}>
            <div className={styles.numberCol}>
              <div className={styles.number}>{!isExpendituresGroup ? expenditureOrGroup.number || "-" : ""}</div>
              {canCheck && !isHideCheckbox && <Checkbox checked={isChecked} onChange={handleCheck} />}
              {showDoubleCHeck && <BuildingSectionProvidersPopup providers={expenditureOrGroup.providers} />}
            </div>
            <div className={styles.infoCol}>
              <Info
                expenditure={expenditureOrGroup}
                buildingId={buildingId}
                variant={tab}
                isShared={isShared}
                onOverview={handleSetOverviewState}
                overviewState={overviewState}
                canViewFiles={canViewFiles}
                canAddFiles={permissions.canAddFiles}
                canDeleteFiles={permissions.canDeleteFiles}
                loadSectionExpenditures={loadSectionExpenditures}
                isKSHidden={isKSHidden}
                isExpendituresGroup={isExpendituresGroup}
                handleShowGroupModal={handleShowGroupModal}
                className={showMatching && styles.infoWrapper}
              >
                {/* @ts-ignore */}
                {showMatching ? (
                  <div className={styles.matching}>
                    <BuildingMatchingModal
                      buildingId={buildingId}
                      expenditure={expenditureOrGroup}
                      parentId={parentId}
                      loadSectionExpenditures={loadSectionExpenditures}
                    />
                  </div>
                ) : null}
              </Info>
            </div>
            <div className={styles.countCol}>
              <div>
                {expenditureDisplayedCount as string}
                <br />
                <span
                  title={expenditureDisplayedCount + " (" + expenditureOrGroup.measure + ")"}
                  className={styles.measure}
                >
                  ({expenditureOrGroup.measure})
                </span>
              </div>
              <ChartPopup
                type="info"
                containerClassName={styles.infoContainer}
                onHover={handleSetOverviewState}
                //@ts-expect-error
                accepted={overviewState.count_accepted}
                //@ts-expect-error
                all={expenditureDisplayedCount}
                //@ts-expect-error
                completed={overviewState.count_completed}
                title="Прогресс выполнения"
                customLabels={undefined}
              />
            </div>
            {intervals?.plans?.length > 0 ? (
              <ExpenditureAllPlansPopup
                intervals={intervals.plans}
                className={styles.allPlansPopup}
                expenditure={expenditureOrGroup}
                loadProduct={loadProduct}
                buildingId={buildingId}
                canDeleteFiles={permissions.canDeleteFiles}
                sectionName={sectionName}
                sectionId={sectionId}
                constructionDateStart={constructionDateStart}
                constructionDateEnd={constructionDateEnd}
              />
            ) : (
              <span />
            )}
            <DefaultColumns
              variant={tab}
              expenditure={expenditureOrGroup}
              loadProduct={loadProduct}
              expenditureCount={expenditureDisplayedCount}
              currentInProductionIntervals={currentInProductionIntervals}
              isExpendituresGroup={isExpendituresGroup}
              buildingId={buildingId}
              onExpand={onExpand}
              groupedPlanGroupIntervals={groupedPlanGroupIntervals}
              groupedFactGroupIntervals={groupedFactGroupIntervals}
              loadSectionGroups={loadSectionGroups}
              canAddIntervals={canAddIntervals}
              isInProductionIntervalCompleted={isInProductionIntervalCompleted}
              isShared={isShared}
              isExpanded={isExpanded}
              planIntervals={currentPlanIntervals}
              isPlanIntervalsCompleted={isPlanIntervalsCompleted}
              lsrPlanData={lsrPlanData}
              canDeleteFiles={permissions.canDeleteFiles}
              factIntervals={currentFactIntervals}
              sectionName={sectionName}
              sectionId={sectionId}
              actions={actions}
              directlyAction={directlyAction}
            />
          </div>
          {isShowGroupModal && (
            <ExpenditureGroupModal
              isOpen={isShowGroupModal}
              onClose={handleCloseGroupModal}
              group={expenditureOrGroup}
              isShowButtons={true}
              handleUngroup={handleUngroupExpenditure}
              handleEdit={handleEditExpenditure}
            />
          )}
          {relationOriginInfo && (
            /* @ts-ignore */
            <AddRelationsModal
              relationOriginInfo={relationOriginInfo}
              objectId={objectId}
              handleClose={() => {
                setRelationOriginInfo(null);
              }}
            />
          )}
        </div>
      )}
      {isEditing && (
        <EditExpendituresInHandler
          onCancelEdit={() => onCancelEdit(expenditureOrGroup.id)}
          sectionId={sectionId}
          expenditure={expenditureOrGroup}
          buildingId={buildingId}
        />
      )}
    </>
  );
};

export default React.memo(BuildingExpenditure);
