import cn from "classnames";
import React from "react";
import { useSelector } from "react-redux";

import { summaryTableHighlightingSelector } from "pages/Finance/_TODO/scroll/summaryScrollReducer/selectors";

import valueInThousandsOrMillions from "utils/formatters/valueInThousandsOrMillions";

import styles from "./styles.module.scss";

const MoneyCell = React.memo(
  (props: { data: any; measure?: number; isDiff?: boolean; isPercents?: boolean; className?: string }) => {
    const { data, measure = 1000, isDiff, isPercents } = props;
    const tableHighlighting = useSelector(summaryTableHighlightingSelector) || {};

    const dataForDisplay = isPercents
      ? valueInThousandsOrMillions(data, 1, undefined)
      : valueInThousandsOrMillions(data, measure, undefined);

    return (
      <div>
        <span
          className={cn(styles.moneyCell__text, props.className, {
            [styles.negative]: isDiff && tableHighlighting.negative && data < 0,
            [styles.positive]: isDiff && tableHighlighting.positive && data > 0,
          })}
          title={dataForDisplay as string}
        >
          {dataForDisplay}
        </span>
      </div>
    );
  }
);

const renderMoneyCell = (data: any, measure?: number, isDiff?: boolean, className?: string, isPercents?: boolean) => {
  return <MoneyCell data={data} measure={measure} isDiff={isDiff} className={className} isPercents={isPercents} />;
};

export default renderMoneyCell;
