import { MutableRefObject, useEffect } from "react";
import { useDispatch } from "react-redux";

import { getTableViewWidthPercent } from "pages/Finance/Summary/ui/TableSide/Table/utils";

import { summaryActions } from "./summaryScrollReducer/actions";

export const useFinanceTableResize = (tableRef: MutableRefObject<null>) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      const tableViewWidthPercent = getTableViewWidthPercent(tableRef.current);
      dispatch(summaryActions.setTableViewWidthPercent(tableViewWidthPercent));
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
};
