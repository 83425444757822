const pluralRules = new Intl.PluralRules('ru-RU');

export function daysPluralSuffix(n: number) {
    const rule = pluralRules.select(n);
    let suffix;

    switch (rule) {
        case 'one':
            suffix = 'день';
            break;
        case 'few':
            suffix = 'дня';
            break;
        case 'many':
            suffix = 'дня';
            break;
        default:
            suffix = 'дней';
    }

    return suffix;
}