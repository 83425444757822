import { combineReducers } from "redux";

import { summaryScrollReducer } from "pages/Finance/_TODO/scroll/summaryScrollReducer/reducer";

import audit from "./Audit/model/slice";
import forecast from "./Forecast/model/slice";
import summary from "./Summary/model/slice";

export const financeReducer = combineReducers({
  summary,
  forecast,
  audit,
  summaryScrollReducer,
});
