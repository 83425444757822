import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { scrollTable } from "../getTableViewWidthPercent";
import { summaryActions } from "./summaryScrollReducer/actions";
import { summaryYearSelector, tableScrollPositionSelector } from "./summaryScrollReducer/selectors";
import { useFinanceDrag } from "./useFinanceDrag";

import { SCROLL_EVENT_TARGETS } from "./summaryScrollReducer/constants";

export const useSummaryScroll = (isNotTable?: boolean) => {
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const year = useSelector(summaryYearSelector);
  const scrollPosition = useSelector(tableScrollPositionSelector);

  useFinanceDrag({ targetRef: tableRef });

  useEffect(() => {
    if (scrollPosition.eventFrom !== SCROLL_EVENT_TARGETS.TABLE) {
      scrollTable(tableRef.current, scrollPosition.percent, isNotTable);
    }
    if (isNotTable) {
      scrollTable(tableRef.current, scrollPosition.percent, isNotTable);
    }
  }, [scrollPosition, isNotTable]);

  useEffect(() => {
    dispatch(summaryActions.setTableScrollPosition({ scrollPosition: 0 }));
  }, []);

  return {
    tableRef,
    year,
  };
};
